<template>
  <div>
    <vue-progress-bar></vue-progress-bar>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<script>
// const target = document.getElementsByClassName('bee-table-row');
// target.onmouseover = function(event){
//   alert('hi');
// 	target.style.background = "red";
// }
// target.onmouseout = function(event){
// 	target.style.background = "white";
// }
// window.onload=function(){
//     var target = document.getElementsByClassName('bee-table-row'); 
//     console.log(target);

//     document.getElementById('bee-table-row').addEventListener('mouseover', function(){
//         alert('Hi');
//         changeColor(target, 'red');
//     });
// }
// function changeColor(coll, color){

//     for(var i=0, len=coll.length; i<len; i++)
//     {
//         coll[i].style["background-color"] = color;
//     }
// }

</script>

<style lang="scss">

  // Import Main styles for this application
  @import 'assets/scss/style';
  body{
    font-size: 1.15rem;
    .btn{
      font-size: 1.00rem;
      font-weight:bold;
    }
    .modal-title{
      font-size: 1.50rem;
    }
    .form-control {
      font-size: 1.00rem;
    }
  }
  .magnifying-glass:hover {
    cursor: pointer;
  }
  .vgt-wrap__footer.vgt-clearfix{
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .vgt-global-search.vgt-clearfix{
    padding:6px 6px;
  }

  .vgt-table.bordered th{
    padding: 8px 24px 8px 6px;
  }
  .vgt-table.bordered td{
    padding: 8px 8px 8px 8px;
  }
  .required:after {
    content: '*';
    color:red;
    font-size: 15px;
    vertical-align: middle;
  }
  @import "src/assets/scss/vue-custome-theme/custom-theme";
  
</style>

<style>
  .bee-table-cell span{
    font-weight:bold;
    font-size: .85rem;
  }

  .bee-table-cell div{
    font-weight:bold;
    font-size: .85rem;
  }

  div.bee-table-cell{
    padding: 2px 5px;
    /*padding-left: 9px;*/
    /*padding-right: 9px;*/
  }
  .header-title{
    font-size: 1rem;
    font-weight: bold;
  }
  .absolute-center{
    position: absolute;top: 0;left: 50%;transform: translateX(-50%);-webkit-transform: translateX(-50%);-ms-transform: translateX(-50%);
  }

  .bee-table-border  td, .bee-table-border  th{
   border: solid 1px #ABA7A7 !important;
  }

  .bee-table-row  tr:nth-child(1) td{
    border-top: none;
  }
  /**/
  .clickable{
    cursor: pointer;
  }
  .bee-table-tbody tr:first-child td{
    border-top: none !important;
  }
  .bee-table-wrapper.bee-table-wrapper-with-border{
    border-top: none;
  }

  button[aria-pressed].active:before{
    /*color:red !important;*/
    content: '✓';
    font-size: 11px;
    vertical-align: middle;
  }

  @import 'assets/scss/fromStyle.css';
</style>
